.customDropDown {
  background: var(--color-championship-white);
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  max-height: initial;
  overflow-y: scroll;
  z-index: 9;
  transition: all 0.3s;
  border: 1px solid var(--color-tarmac-grey-900);
  @media (--mobile) {
    top: 63px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media (--tablet) {
    position: absolute;
    height: 288px;
    z-index: 1;
    max-width: 680px;
    margin-top: var(--spacing-2);
    left: 0;
    top: 100%;
  }

  & .search {
    position: relative;
  }

  & .loading,
  & .noResult {
    padding: 0 var(--spacing-5);
  }

  & .title {
    padding: 0 var(--spacing-5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (--tablet) {
      padding: 0 var(--spacing-6);
    }

    & .closeIcon {
      width: var(--spacing-6);
      height: var(--spacing-6);
      @media (--tablet) {
        display: none;
      }
    }
  }

  & .customDropDownSection {
    & .heading {
      padding: 0 var(--spacing-5);
      margin: var(--spacing-2) 0;

      @media (--tablet) {
        padding: 0 var(--spacing-6);
        margin: var(--spacing-3) 0;
      }
    }

    & ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }
  }
}
