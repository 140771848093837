.labelWrapper {
  display: flex;
  padding: 0 var(--spacing-5);

  cursor: pointer;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 48px;

  &:hover {
    background-color: var(--color-tarmac-grey-50);
  }
}

.popularMakesTitle {
  margin: var(--spacing-3) 0 var(--spacing-2);
}

.resetButton {
  && {
    display: block;
    margin-left: auto;
    padding: 0;
    color: var(--color-tarmac-grey-700);
  }
}

.makes {
  overflow-y: scroll;
  max-height: 400px;

  &.searching {
    @media (--mobile) {
      max-height: calc(100vh - 200px);
    }
  }
}

.applyButtonMakes {
  [data-service-consumer='native'] & {
    max-height: 304px;
  }
}
