.header {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-5);
  width: 100%;
  height: 64px;
  position: fixed;
  top: 64px;

  @media (--tablet) {
    top: 74px;
  }

  @media (--tablet-l) {
    padding: var(--spacing-5) 0;
    top: 112px;
  }

  &[data-has-primary-nav='true'] {
    top: 64px;

    @media (--tablet-l) {
      top: 72px;
    }
    @media (--desktop-l) {
      top: 80px;
    }
  }

  [data-has-footer-sticky='true'] & {
    top: 0;
    @media (--tablet-l) {
      top: 72px;
    }
    @media (--desktop-l) {
      top: 80px;
    }
  }

  [data-service-consumer='native'] & {
    top: 0;
  }
}

.slideShowGalleryHeader {
  position: initial;
  align-items: center;
  top: 64px;
}

.nums {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: var(--color-championship-white);
}

.backButton {
  && {
    color: var(--color-championship-white);
    text-decoration: unset;
    min-height: initial;

    &:hover,
    &:focus {
      color: var(--color-championship-white);
    }
  }
}

.auxBtns {
  color: var(--color-championship-white);
}
