.labelWrapper {
  display: flex;
  padding: var(--spacing-3) var(--spacing-5);
  cursor: pointer;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 48px;
  @media (--tablet) {
    padding: 0 var(--spacing-5);
  }

  & input[type='radio'] {
    margin-right: calc(var(--spacing-2) + 2px);
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid var(--color-tarmac-grey-700);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (--tablet) {
      opacity: 0;
      position: absolute;
    }
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: scale(0);
      background-color: var(--color-tarmac-grey-700);
    }
  }

  & input[type='radio']:checked::before {
    transform: scale(1);
  }

  &:hover {
    background-color: var(--color-tarmac-grey-50);
  }

  &.active {
    & :global(.subHeading) {
      font-weight: var(--font-weight-medium);
      color: var(--color-tarmac-grey-900);
    }
    @media (--tablet) {
      &::after {
        content: '';
        width: 0;
        bottom: 0;
        position: absolute;
        border-left: 4px solid var(--color-tarmac-grey-900);
        top: 0;
        left: 0;
      }
    }
  }
}
