.error {
  color: var(--color-red-700);
  margin: 0;
}

span:has(> .error) {
  padding-left: 0;
  font-size: 0.75rem;
  width: auto;
  white-space: nowrap;

  @media (min-width: 768px) {
    position: relative;
    top: 3px;
    height: 0;
  }
}
