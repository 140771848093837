.quickSearch {
  width: 100%;
  padding: 0 var(--spacing-5);
  margin-bottom: var(--spacing-5);
  & .icon {
    width: var(--spacing-6);
    height: var(--spacing-6);
    margin-right: var(--spacing-3);
  }
}
