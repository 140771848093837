.mileageFilter {
  padding: var(--spacing-2) 0 var(--spacing-4);

  & .infoLine {
    display: block;
    margin-bottom: var(--spacing-6);
  }

  & .conditionTypeBtns {
    margin-bottom: var(--spacing-6);
    width: 100%;
  }

  & .minMaxInputs {
    display: flex;
    gap: var(--spacing-2);
  }

  & .auxInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-5);

    & .resultCount {
      color: var(--color-neutral-500);
    }

    & button {
      padding-right: 0;
    }
  }
}
