.makeLabel {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: space-between;

  & .logo {
    width: 24px;
    height: 24px;
    margin-right: var(--spacing-2);
  }

  & .name {
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    justify-content: space-between;
  }
}
