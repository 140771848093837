.container {
  margin-top: 40%;

  @media (--tablet) {
    margin-top: 20%;
  }

  @media (--tablet-l) {
    margin: 0;
    align-self: center;
    width: 730px;
  }

  @media (--desktop) {
    width: 800px;
  }
}
