.container {
  display: flex;
  align-self: stretch;
  height: 100%;
  width: 100%;
  justify-self: stretch;

  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 64px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  bottom: 0;
  right: 0;
  pointer-events: none;
  gap: 24px;
  flex-wrap: wrap;

  &.positionAbsolute {
    position: absolute;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  pointer-events: all;
}

.dots {
  display: flex;
  flex-direction: row;
}

.dot {
  width: 24px;
  height: 24px;
  border: 3px solid var(--color-mica-blue-400);
  border-radius: 50%;
  float: left;
  margin: 0 5px;
  transform: scale(0);
  -webkit-animation: fx 1000ms ease infinite 0ms;
  animation: fx 1000ms ease infinite 0ms;
}

.dot:nth-child(2) {
  -webkit-animation: fx 1000ms ease infinite 300ms;
  animation: fx 1000ms ease infinite 300ms;
}

.dot:nth-child(3) {
  -webkit-animation: fx 1000ms ease infinite 600ms;
  animation: fx 1000ms ease infinite 600ms;
}

@-webkit-keyframes fx {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fx {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
