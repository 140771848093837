.resetButton {
  && {
    display: block;
    margin-left: auto;
    padding: 0;
    color: var(--color-tarmac-grey-700);
  }
}

.models {
  overflow-y: scroll;
  max-height: 250px;

  &.searching {
    @media (--mobile) {
      max-height: calc(100vh - 200px);
    }
  }
}

.applyButtonMakes {
  [data-service-consumer='native'] & {
    max-height: 304px;
  }
}
