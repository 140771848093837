.imageWrapper {
  display: block;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  margin-top: var(--spacing-16);

  @media (--tablet-l) {
    display: grid;
    grid-auto-rows: 20.8vw;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: dense;
    grid-gap: var(--spacing-2);

    & > li {
      list-style-type: none;
      cursor: pointer;
    }

    & :nth-child(6n + 1) {
      grid-row: span 2;
      grid-column: 1 / span 2;
    }

    & :nth-child(6n + 6) {
      grid-row: span 2;
      grid-column: 2 / span 2;
    }

    & :nth-child(6n + 5) {
      grid-column: 1;
    }
  }

  @media (--desktop-l) {
    grid-auto-rows: 287px;
  }
}

.videoWrapper {
  display: block;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  margin-top: var(--spacing-16);
}

.image {
  background-size: auto;
  width: 100%;
  height: 67vw;
  object-fit: cover;
  margin-bottom: 2px;

  @media (--tablet) {
    height: 100%;
  }
}
