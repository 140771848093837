.wrapper {
  --scale: 1;
  width: 100%;
  max-width: 100%;
  visibility: hidden;

  &[data-is-visible='true'] {
    visibility: visible;
  }

  &[data-with-margin='true'] {
    margin: var(--spacing-10) 0;
  }

  & [data-unit-id] {
    display: flex;
    align-items: center;
    justify-content: center;

    position: sticky;
    top: calc(var(--stickyPaddingOffset) * 1px);
  }
}

.ad {
  /* display: none; */
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  position: relative;
  min-height: 1px;
  min-height: calc((var(--renderedHeight) + var(--stickyPadding)) * 1px);
  contain: content;

  [data-is-visible='true'] & {
    display: flex;
  }

  [data-is-viewable='false'] & {
    height: 1px;
  }

  [data-should-scale='true'] & {
    width: 970px;
    max-width: 970px;
    transform: scale(var(--scale));
    transform-origin: top left;
  }

  [data-service-consumer='native'] & {
    opacity: 0;

    transition: 0.6s;
    transition-delay: 0.2s;

    &:has(iframe) {
      opacity: 1;
    }
  }
}

.placeholderAd {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  background-size: cover;
  background-position: center;
  align-self: stretch;
  justify-self: stretch;
  align-content: center;
  justify-content: center;
  opacity: 1;
  z-index: -1;

  & img {
    width: auto;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.divider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-2) 0;
  margin: var(--spacing-4) 0;
}

.border {
  height: 1px;
  width: 100%;
  background: var(--color-tarmac-grey-500);
}

.text {
  font-size: 12px;
  font-weight: bold;
  background-color: white;
  padding: 0 var(--spacing-2);
  position: absolute;
}

.adUnit {
  align-self: center;
}

.loader {
  width: 40px;
  height: 40px;
  border: 5px solid #eee;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader:after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  border: 5px solid #eee;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse {
  0%,
  60%,
  100% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
}

.adsNotVisible {
  & > div:first-child {
    height: auto !important; /* override the height set by the ad if it's not visible so that it doesn't overlap on other DOM*/
  }
}
