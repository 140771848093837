.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: 100%;
  margin-top: 30%;

  @media (--tablet) {
    margin-top: 0;
  }
  & .iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > div {
    z-index: 0;
  }
}
