.container {
  background: black;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 99;
  height: 100%;
  overflow-y: hidden;
  top: 64px;
  @media (--tablet) {
    top: 72px;
  }
  @media (--tablet-l) {
    top: 112px;
  }
  &[data-has-primary-nav='true'] {
    top: 64px;

    @media (--tablet-l) {
      top: 72px;
    }
    @media (--desktop-l) {
      top: 80px;
    }
  }
  [data-has-footer-sticky='true'] & {
    top: 0;
    z-index: 1000;
    @media (--tablet-l) {
      z-index: 99;
      top: 72px;
    }
    @media (--desktop-l) {
      top: 80px;
    }
  }
  [data-service-consumer='native'] & {
    top: 0;
  }
}

.gridContainer {
  && {
    position: relative;
    padding: 0;

    @media (--tablet-l) {
      padding: 0 var(--spacing-8);
      padding-bottom: var(--spacing-12);
    }
    @media (--desktop-l) {
      padding: 0 var(--spacing-16);
    }
  }
}

.patchWorkGalleryContainer {
  && {
    padding: 0;
    padding-bottom: var(--spacing-20);
    height: inherit;

    @media (--tablet-l) {
      padding: 0 var(--spacing-8);
      padding-bottom: 120px;
    }

    @media (--desktop-l) {
      padding: 0 var(--spacing-16);
      padding-bottom: 120px;
    }

    [data-service-consumer='native'] & {
      padding-bottom: 0;
    }
  }
}

.galleryTabs {
  display: flex;
  justify-content: center;
  gap: var(--spacing-3);
  width: 100%;
  position: fixed;
  bottom: 48px;
  left: 0;

  & button {
    background: var(--color-championship-white);
    height: auto;
    min-height: initial;
    padding: 6px var(--spacing-4);

    @media (--tablet-l) {
      padding: 14px var(--spacing-6);
    }

    &:focus,
    &:hover {
      background: var(--color-championship-white);
    }

    & > span {
      color: var(--color-tarmac-grey-700);
      font-size: 14px;

      @media (--tablet-l) {
        font-size: 16px;
      }

      &:first-child {
        & svg {
          font-size: 20px !important;
        }
      }
    }
  }
}

.videoBtnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  border-bottom: 1px solid var(--neutral-300-disabled, #b5b5b5);
  overflow-x: clip;
  flex-wrap: wrap;

  &.addOpacity {
    opacity: 0.3;
  }

  & > * {
    flex: 1 1 auto;
    padding-left: 0;
    padding-right: 0;

    &:not(:first-child) {
      border-left: 1px solid var(--color-tarmac-grey-50);
    }
  }

  & .videoButton {
    color: var(--color-tarmac-grey-700);
    background: transparent;
  }
}
