.tradeInWrapper {
  position: relative;
  margin: 0 auto;
  padding: var(--spacing-5) var(--spacing-5);
  background-color: #fff;

  @media (--tablet) {
    padding: var(--spacing-3) var(--spacing-3) var(--spacing-3) 10px;
  }
}

.row {
  @media (--mobile) {
    width: 100%;
  }
}

.col {
  & > div,
  & > button {
    width: 100%;
  }

  /* remove borders from inputs */
  & fieldset {
    border: none;
  }

  & span[role='alert'] {
    padding-left: 0;
  }

  /* move input errors under tab container */
  @media (--tablet) {
    & span[role='alert'] {
      position: absolute;
      bottom: -20px;
    }
  }
}

.valuationCol {
  display: flex;
  flex-wrap: wrap;

  & label,
  & .divider,
  & a {
    flex: 0 0 100%;
  }

  & p {
    flex: 1 0 auto;
    margin: var(--spacing-1) 0 0;
  }

  & .vehicleName {
    overflow: hidden;
    text-overflow: ellipsis;
    /* 46px is the width of the edit button with its margins etc */
    max-width: calc(100% - 46px);
    white-space: nowrap;
  }

  & .editBtn {
    flex: 0 0 auto;
    align-self: end;
    color: var(--color-neutral-700);
  }

  @media (--tablet) {
    & label,
    & p {
      margin-left: 10px;
    }

    & .editBtn {
      margin-right: 10px;
    }
  }
}

.col,
.valuationCol {
  margin-bottom: var(--spacing-3);

  &:last-child {
    margin-bottom: 0;
  }

  @media (--tablet) {
    margin-bottom: 0;

    &:first-child {
      border-right: solid 1px var(--color-neutral-300);
    }

    & .divider {
      display: none;
    }
  }
}

.divider {
  margin-top: var(--spacing-2);
  margin-bottom: 0;
  border: none;
  border-bottom: solid 1px var(--color-neutral-300);
}

.input {
  padding-left: 0;

  @media (--tablet) {
    padding-left: var(--spacing-2);
  }
}
